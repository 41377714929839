<template>
  <div>
    <el-card>
      <div class="info-box">
        <div class="info-one">
          <div class="box">
            <span class="left">患者姓名：</span>
            <span class="right">{{ formInline.patientName }}</span>
          </div>
          <div class="box">
            <span class="left">身份证号：</span>
            <span class="right">{{ formInline.idCardNo }}</span>
          </div>
          <div class="box">
            <span class="left">医保卡号：</span>
            <span class="right">{{ formInline.medicalCardNo }}</span>
          </div>
          <div class="box">
            <span class="left">出生日期：</span>
            <span class="right">{{ formInline.birth }}</span>
          </div>
        </div>
        <div class="info-one">
          <div class="box">
            <span class="left">患者年龄：</span>
            <span class="right">{{ formInline.age }}</span>
          </div>
          <div class="box">
            <span class="left">患者性别：</span>
            <span class="right">{{ gender_name(formInline.gender) }}</span>
          </div>
          <div class="box">
            <span class="left">管理医生：</span>
            <span class="right">{{ formInline.docName }}</span>
          </div>
        </div>
      </div>
      <div style="margin-left: 12px;width: 600px;">
        <el-form ref="form" :model="form" label-width="90px">
          <el-form-item label="报告：">
            <el-link @click="see()" :underline="false" type="primary"
              >查看</el-link
            >
          </el-form-item>
          <el-form-item label="随访时间：">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="form.time"
              style="width: 85%"
              size="small"
              type="date"
              placeholder="请选择时间"
          /></el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="form.visitRecommend"
              style="width: 85%"
              :rows="5"
              type="textarea"
              placeholder="请输入"
            />
          </el-form-item>
        </el-form>
      </div>

      <div class="btn-box">
        <el-button @click="save" class="btn" type="primary">保存</el-button>
        <el-button @click="DeleteSettings" class="btn delete-btn"
          >删除设置</el-button
        >
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-card>
    <reportDialog
      v-model="report_model"
      :list="report_list"
      :patientOpenId="patientOpenId_"
      :patientId="patientId"
    />
  </div>
</template>
<script>
import reportDialog from "@/components/TestKit/dialog/report-dialog";

export default {
  name: "subjectEdit",
  components: {
    reportDialog,
  },
  data() {
    return {
      formInline: {},
      form: {
        time: "",
        visitRecommend: "",
      },
      range: [
        {
          value: "1",
          text: "男",
        },
        {
          value: "0",
          text: "女",
        },
      ],
      report_model: false,
      report_list: [],
      patientOpenId_: "",
      patientId: "",
    };
  },
  computed: {
    gender_name() {
      return function(row) {
        let str;
        str = this.range
          .filter((item) => item.value === row)
          .map((item) => item.text)[0];
        return str;
      };
    },
  },
  mounted() {
    // console.log(this.$route.query);
    let str = this.$route.query.info;
    // console.log(str);

    this.formInline = JSON.parse(str);
    this.form.time = this.formInline.visitTime;
    this.form.visitRecommend = this.formInline.visitRecommend;
    console.log(this.formInline);
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    see() {
      this.patientOpenId_ = this.formInline.patientOpenId;
      this.patientId = this.formInline.patientId;
      this.$get(`colondoc/report/${this.formInline.patientId}`).then((res) => {
        if (res) {
          let data_ = res.data;
          this.report_list = data_;
          this.report_model = true;
        }
      });
    },
    save() {
      if (!this.form.time) return this.$message.error("请选择时间");
      
      this.$post("report/submit", {
        patientId: this.formInline.patientId,
        time: `${this.form.time} 00:00:00`,
        visitRecommend: this.form.visitRecommend,
        id: this.formInline.visitId,
      }).then((res) => {
        if(!res) return false

        this.$message({
          message: "设置成功",
          type: "success",
        });
        this.$router.go(-1);
      });
    },
    DeleteSettings() {
      this.$get(`hh/polypInfo/delete/${this.formInline.visitId}`).then(
        (res) => {
            if(!res) return false
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.$router.go(-1);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.info-box {
  .info-one {
    display: flex;
    align-items: center;
    gap: 60px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
    .box {
      display: flex;
      align-items: center;
      width: 280px;
      gap: 30px;
      min-width: 280px;
      .left {
        font-size: 14px;
        color: #606266;
      }
      .right {
        font-size: 14px;

        color: #3d3d3d;
      }
    }
  }
}
.btn-box {
  display: flex;
  justify-content: flex-end;
  .btn {
    width: 97px;
    height: 40px;
  }
  .delete-btn {
    border: 1px solid #ff7c7c;
    color: #ff0000;
  }
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
